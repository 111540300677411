<template>
  <div>
    <div class="flash-content" v-show="showFlashContent">
      <div class="flash-title">
        <img src="@/assets/images/time_newflash@2x.png" class="iocn-title" alt="">
        <span class="time">{{createTime|formatDate()}}</span>
      </div>

      <div class="flash-detail">
        <div class="flash-detail-title" v-if="flashTitle">{{flashTitle}}</div>
        <div class="flash-ueditor" v-html="content" ref="uEditorContent" id="uEditorContent">
        </div>
      </div>


    </div>
  </div>

</template>

<script>
  export default {
    data () {
      return {
        createTime:"",
        flashTitle:"",
        content:"",
        link:"",
        ifOriginal:1,
        showFlashContent:false,
      }
    },
    watch: {
      //检测路由变化
      '$route'(to, from) {
        this.getFlashInfo()
      },
    },
    filters: {
      formatDate(v) {
        if(v){
          var dateObj = new Date(v);
          var todaysDate = new Date();
          var isToday=false;//是否为今天

          var month = dateObj.getMonth() + 1;
          var day = dateObj.getDate();
          var hours = dateObj.getHours();
          var minutes = dateObj.getMinutes();
          var seconds = dateObj.getSeconds();
          if (month < 10) {
            month = "0" + month;
          }
          if (day < 10) {
            day = "0" + day;
          }
          if (hours < 10) {
            hours = "0" + hours;
          }
          if (minutes < 10) {
            minutes = "0" + minutes;
          }
          if (seconds < 10) {
            seconds = "0" + seconds;
          }
          if(dateObj.setHours(0,0,0,0) == todaysDate.setHours(0,0,0,0)){
            isToday=true;
          }
          if(isToday){
            return hours + ':' + minutes;
          }else if(dateObj.getFullYear()==new Date().getFullYear()){
            return  month + '-' + day + ' ' + hours + ':' + minutes;
          }else{
            var UnixTimeToDate = dateObj.getFullYear() + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
            return UnixTimeToDate;
          }
        }else{
          return "";
        }


      }
    },
    created(){
      this.getFlashInfo();
    },
    methods:{
      async getFlashInfo(){
        const { token: Authorization, eid } = this.$route.query

        if(this.$route.query.flashId&&this.$route.query.flashId!='undefined') {
          let params = {
            flashId: this.$route.query.flashId,
          }
          let res = await this.$api.cj.getFlashInfo(params, { Authorization, eid })
          if (res.retCode == 200) {
            this.flashTitle = res.data.flashData.flashTitle == null ? "" : res.data.flashData.flashTitle;
            this.createTime = res.data.flashData.createTime;
            this.content = res.data.flashData.content.replace(/static.tfcaijing.com/g,'zebra.tfcaijing.com');
            this.link = res.data.flashData.link;
            this.ifOriginal = res.data.flashData.ifOriginal;
            this.showFlashContent=true;
          }
        }else if(sessionStorage.getItem('previewData')) {
          let previewData=JSON.parse(sessionStorage.getItem('previewData'));
          this.flashTitle = previewData.flashTitle;
          this.createTime = previewData.createTime;
          this.content = previewData.content.replace(/static.tfcaijing.com/g,'zebra.tfcaijing.com');
          this.link = previewData.link;
          this.ifOriginal = previewData.ifOriginal;
          this.showFlashContent=true;
        }
      },
    },
  }
</script>

<style scoped>
  /*快讯详情*/
  .flash-title{
    display: flex;
    align-items: center;
    margin-bottom: .56rem;
  }

  .iocn-title{
    width: .6rem;
    height: .6rem;
    margin-right: .2rem;
}

  .flash-content{
      border-radius: .56rem .56rem 0 0;
      background: #fff;
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: .72rem .68rem;
  }
  .flash-detail .flash-ueditor{
    font-size: .64rem;
    font-family: PingFangSC-Regular,PingFang SC;
    font-weight: 400;
    letter-spacing: .01rem;
    color: #333;
    line-height: .92rem;
  }
</style>
